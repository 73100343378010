import React from 'react';

const NoMatch = props => {
  return(
    <div className="aboutDetails">
      <h1>404</h1>
      <p>Sorry! The page you're looking for does not exist. If you expected it to work, try complaining <a href="https://twitter.com/BulletPointsVG">@BulletPointsVG</a>.</p>
      <p>In the meantime, feel free to peruse the Back Issues.</p>
</div>
    )
}

export default NoMatch
